<template>
  <div id="orders-details">
    <promises-requests-details-list :promises="promises"></promises-requests-details-list>
  </div>
</template>

<script>
  import PromisesRequestsDetailsList from './components/PromisesRequestsDetailsList'

  export default{
    name: 'OrdersDetails',
    components: {PromisesRequestsDetailsList},
    props: ['promises'],
    data() {
      return {
      }
    },
  }
</script>
